





import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class CmxMap extends Vue {
  content: object = {};

  // 获取关于我们cms内容
  getContent () {
    this.$Api.cms.getContentByDevice({ ContentId: 20305, IsMobile: true }).then(result => {
      this.content = result.CMS.Body;
    });
  }
  mounted () {
    this.getContent();
  }
}
